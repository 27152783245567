























import type { SchedulerStatus, WorkerStatus } from "@/api/Workers"
import Workers from "@/api/Workers"
import PipelinesPage from "@/components/PipelinesPage.vue"
import WorkerCards from "@/components/WorkerCards.vue"
import { useStatus } from "@/composables/status"
import { useUser } from "@/store/user"
export default defineComponent({
  components: { PipelinesPage, WorkerCards },
  setup(_, { root }) {
    const user = useUser()
    const { statusAlert, setStatus, resetStatus } = useStatus()
    const loading = reactive({
      status: true,
      workers: true,
    })
    const workers = ref([] as WorkerStatus[])
    const status = ref<SchedulerStatus | null>(null)

    async function fetchStatus() {
      resetStatus()
      const response = await Workers.status(user.authConfig)
      if (response.data !== null) {
        status.value = response.data
      } else {
        setStatus("error", response.error)
      }
      loading.status = false
    }

    async function fetchWorkers() {
      const response = await Workers.get(user.authConfig)
      if (response.data !== null) {
        workers.value = response.data
      } else {
        setStatus("error", response.error)
      }
      loading.workers = false
    }

    onMounted(() => {
      fetchStatus()
      fetchWorkers()
    })
    watchEffect(() => {
      if (!user.isConnected) root.$router.replace("/login")
    })
    return { loading, status, statusAlert, workers }
  },
})
