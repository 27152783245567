




















import type { WorkerStatus } from "@/api/Workers"
export default defineComponent({
  props: {
    loading: Boolean,
    workers: {
      required: true,
      type: Array as () => WorkerStatus[],
    },
  },
})
